module controllers {
    export module master {
        interface ISupplierUpdateScope extends ng.IScope {
        }

        interface ISupplierUpdateScopeParams extends ng.ui.IStateParamsService {
            comId: number;
        }

        export class supplierUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'supplierService',
                'termsOfPaymentService',
                'incotermService',
                'currencyService',
                'paymentMethodService',
                'cartageContractorService',
                'supplierGradingService',
                'templateService',
                'termsOfDeliveryService',
                'routeCodeService',
                'transportModeService',
                'consigneeService',
                'costModelService',
                'counterService',
            ];

            comId: number;
            supplierMaster: interfaces.master.ISupplierMaster;

            breadCrumbDesc: string;

            public isLoadingDetail: boolean = false;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            bankChargesList: Array<interfaces.applicationcore.IDropdownModel>;
            termsOfDeliveryList: Array<interfaces.applicationcore.IDropdownModel>;

            apiSupplierTemplate: uiGrid.IGridApi;
            apiSupplierBanking: uiGrid.IGridApi;

            showAll: boolean = false;

            constructor(
                private $scope: ISupplierUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ISupplierUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public supplierService: services.master.supplierService,
                public termsOfPaymentService: services.master.termsOfPaymentService,
                public incotermService: services.applicationcore.incotermService,
                public currencyService: services.applicationcore.currencyService,
                public paymentMethodService: services.master.paymentMethodService,
                public cartageContractorService: services.master.cartageContractorService,
                public supplierGradingService: services.master.supplierGradingService,
                public templateService: services.reporting.templateService,
                public termsOfDeliveryService: services.master.termsOfDeliveryService,
                public routeCodeService: services.master.routeCodeService,
                public transportModeService: services.applicationcore.transportModeService,
                public consigneeService: services.master.consigneeService,
                public costModelService: services.costing.costModelService,
                public counterService: services.master.counterService,
            ) {

                this.comId = $stateParams.comId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadSupplier(this.comId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadSupplier(Id: number) {
                return this.supplierService.getSupplier(Id).query((data: interfaces.master.ISupplierMaster) => {

                    this.supplierMaster = data;

                    this.breadCrumbDesc = this.supplierMaster.Code + " - " + this.supplierMaster.Name;

                    this.loadBankChargesTypes();
                    this.loadTermsOfDeliveries();
                    this.getDocuments(this.showAll);

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwSupplierTemplate.data = this.supplierMaster.SupplierTemplateList;
                        this.gvwSupplierBanking.data = this.supplierMaster.SupplierBankList;
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;

            }

            loadTermsOfPayment(searchText: string) {
                return this.termsOfPaymentService.getTermsOfPaymentDropdown(this.supplierMaster.EntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadIncoterms() {
                return this.incotermService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            incoTermChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.supplierMaster.TermsOfDelivery = null;
                if (model) {
                    this.termsOfDeliveryList = [];
                    this.loadTermsOfDeliveries();
                }
            }

            termsOfPaymentChanged(model: interfaces.applicationcore.IDropdownModel) {                
                if (model) {
                    return this.termsOfPaymentService.getTermsOfPaymentDefaults(model.Id).query((data:interfaces.master.ITermsOfPaymentDefaults)=>{
                            if (data){
                                this.supplierMaster.EarlySettlementDiscountDays = data.EarlySettlementDiscountDays;
                                this.supplierMaster.EarlySettlementDiscountPercentage = data.EarlySettlementDiscountPercentage;
                            }
                    });
                }
                else{
                    this.supplierMaster.EarlySettlementDiscountDays = 0;
                    this.supplierMaster.EarlySettlementDiscountPercentage = 0;
                }
            }

            loadTermsOfDeliveries() {
                var defered = this.$q.defer();

                if (!this.supplierMaster || !this.supplierMaster.Incoterm) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.termsOfDeliveryService.getDropdownList().query({ ownerEntityId: this.supplierMaster.EntityId, incoTermId: this.supplierMaster.Incoterm.Id }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                    this.termsOfDeliveryList = list;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            loadPaymentMethods() {
                return this.paymentMethodService.getDropdownList(this.supplierMaster.EntityId).query({
                }, (resultList) => {
                }).$promise;
            }

            loadRoutes() {
                return this.routeCodeService.getDropdownListAll(this.supplierMaster.EntityId, true).query({
                }, (resultList) => {
                }).$promise;
            }

            loadTransportModes() {
                return this.transportModeService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            loadConsignees(searchText: string) {
                return this.consigneeService.getDropdownList(this.supplierMaster.EntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadELCCostModels() {
                return this.costModelService.getDropdownList().query({
                    ownerEntityId: this.supplierMaster.EntityId,
                    code: "",
                    name: "",
                    typeId: 222
                }, (resultList) => {
                }).$promise;
            }

            loadGLCCostModels() {
                return this.costModelService.getDropdownList().query({
                    ownerEntityId: this.supplierMaster.EntityId,
                    code: "",
                    name: "",
                    typeId: 244
                }, (resultList) => {
                }).$promise;
            }

            loadConsignmentCounters() {
                return this.counterService.getDropdownList().query({
                    ownerEntityId: this.supplierMaster.EntityId,
                    counterTypeId: 383,
                    isInbound: true
                }, (resultList) => {
                }).$promise;
            }

            loadCartageContractors() {
                return this.cartageContractorService.getDropdownList().query({
                    ownerEntityId: this.supplierMaster.EntityId
                }, (resultList) => {
                }).$promise;
            }

            loadToleranceDirections() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.AmountToleranceDirection
                }, (resultList) => {
                }).$promise;
            }

            loadCreditTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CreditType
                }, (resultList) => {
                }).$promise;
            }

            loadDocumentDeliveryMethods() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.DocumentDeliveryMethod
                }, (resultList) => {
                }).$promise;
            }

            loadConfirmationTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.LetterOfCreditConfirmation
                }, (resultList) => {
                }).$promise;
            }

            loadIncotermTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.IncotermType
                }, (resultList) => {
                }).$promise;
            }

            loadProductDocuments() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ProductDocumentType
                }, (resultList) => {
                }).$promise;
            }

            loadTransportDocumentTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.TransportDocumentType
                }, (resultList) => {
                }).$promise;
            }

            loadInsuranceParties() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.InsuranceParty
                }, (resultList) => {
                }).$promise;
            }

            loadCoOIssuers() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CertificateOfOriginIssuer
                }, (resultList) => {
                }).$promise;
            }

            loadBankChargesTypes() {
                this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.BankCharges
                }, (resultList) => {
                        this.bankChargesList = resultList;
                });
            }

            loadBuyingAgents(searchText: string) {
                return this.supplierService.getBuyingAgentDropdown(this.supplierMaster.EntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadSupplierGradings() {
                return this.supplierGradingService.getDropdownList(this.supplierMaster.EntityId).query({
                }, (resultList) => {
                }).$promise;
            }

            registerSupplierTemplateApi(gridApi: uiGrid.IGridApi) {
                this.apiSupplierTemplate = gridApi;
            }

            checkBoxTemplateChanged(entity) {
                if (this.apiSupplierTemplate) {
                    this.apiSupplierTemplate.rowEdit.setRowsDirty([entity]);
                }
            }

            templateValueChanged(entity) {
                if (this.apiSupplierTemplate) {
                    this.apiSupplierTemplate.rowEdit.setRowsDirty([entity]);
                }
            }

            insertInlineTemplate() {
                this.supplierMaster.SupplierTemplateList.push(<interfaces.master.ISupplierTemplate>{
                    Id: 0,
                    IsActive: true
                });
            }

            removeInlineTemplate(entity: interfaces.master.ISupplierTemplate) {
                if (entity.Id === 0) {
                    _.remove(this.gvwSupplierTemplate.data, (o) => {
                        return o === entity;
                    });
                    return;
                }
            }

            loadTemplateList() {
                return this.templateService.getDropdownList(this.supplierMaster.EntityId).query({
                }, (resultList) => {
                }).$promise;
            }

            gvwSupplierTemplate: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerSupplierTemplateApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.supplierUpdateCtrl.removeInlineTemplate(row.entity)" ng-disabled="row.entity.Id > 0" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        width: 57,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.supplierUpdateCtrl.insertInlineTemplate()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Template',
                        displayName: '* Template',
                        field: 'Template',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.supplierUpdateCtrl.loadTemplateList()" ng-change="grid.appScope.supplierUpdateCtrl.templateValueChanged(row.entity)" required="true"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-model="row.entity.IsActive" ng-change="grid.appScope.supplierUpdateCtrl.checkBoxTemplateChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };


            registerSupplierBankingApi(gridApi: uiGrid.IGridApi) {
                this.apiSupplierBanking = gridApi;
            }

            checkBoxBankChanged(entity) {
                if (this.apiSupplierBanking) {
                    this.apiSupplierBanking.rowEdit.setRowsDirty([entity]);
                }
            }

            bankValueChanged(entity) {
                if (this.apiSupplierBanking) {
                    this.apiSupplierBanking.rowEdit.setRowsDirty([entity]);
                }
            }

            insertInlineBank() {
                this.supplierMaster.SupplierBankList.push(<interfaces.master.ISupplierBank>{
                    Id: 0,
                    Currency: this.supplierMaster.Currency,
                    StartDate: moment().add(1, 'day'),
                    EndDate: moment().add(2, 'year'),
                    IsActive: true
                });
            }

            loadCurrencyList() {
                return this.currencyService.getCurrencyDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            deleteBank() {
                if (this.apiSupplierBanking && this.apiSupplierBanking.selection.getSelectedRows().length > 0) {
                    var selected = this.apiSupplierBanking.selection.getSelectedRows()[0];
                    if (selected.Id > 0) {
                        this.generalService.displayMessage("You cant delete a current record. Please make it inactive.", Enum.EnumMessageType.Warning);
                    }
                    else {
                        selected.IsActive = false;
                        _.remove(this.gvwSupplierBanking.data, (o) => {
                            return o === selected;
                        });
                    }
                } else {
                    this.generalService.displayMessage("Please select a supplier banking record.", Enum.EnumMessageType.Warning);
                }
            }

            gvwSupplierBanking: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                enableSorting: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                multiSelect: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.registerSupplierBankingApi(gridApi);
                    this.apiSupplierBanking.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiSupplierBanking.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info ng-disabled="row.entity.Id < 1" createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate" updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate"></gts-stamp-info>
                        </div>`,
                        width: 40,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.supplierUpdateCtrl.insertInlineBank()" ng-disabled="(grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails)">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: "Address",
                        displayName: "",
                        field: "Id",
                        enableSorting: false,
                        enableFiltering: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        cellTemplate: `
                        <div class="row GridButton">
                            <div class="col-md-4"> 
                                <gts-address-modal-popup transaction-id="row.entity.Id" transaction-type="9" ng-disabled="(grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails || row.entity.Id < 1 || !row.entity.IsActive)" show-country="true"></gts-address-modal-popup>        
                            </div>
                            <div class="col-md-8">
                                <button type="button" ng-click="grid.appScope.supplierUpdateCtrl.showSupplierIntermediaryBankDetails_click(row.entity)" class="btn btn-primary btn-sm" ng-disabled="grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2">
                                    Intermediary Bank
                                </button>
                            </div>
                        </dvi>`,
                        width: 200,
                        enableCellEditOnFocus: false,
                    },
                    {
                        name: "Currency",
                        displayName: "* Currency",
                        field: "Currency",
                        cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.supplierUpdateCtrl.loadCurrencyList()" required="true"></gts-dropdown>
                                </form>`,
                    },
                    {
                        name: "BankName",
                        displayName: "* Bank",
                        field: "BankName",
                        cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="text" maxlength="70" name="BankName-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                                </p>
                            </form>`,
                    },
                    {
                        name: "SWIFTCode",
                        displayName: "SWIFT",
                        field: "SWIFTCode",
                        cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                    <p class="input-group-sm">
                                 <input type="text" maxlength="12" name="SWIFTCode-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                                    </p>
                            </form>`,
                    },
                    {
                        name: "AccountNumber",
                        displayName: "Account Number",
                        field: "AccountNumber",
                        cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                 <input type="text" maxlength="50" name="AccountNumber-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                                </p>
                            </form>`,
                    },
                    {
                        name: "IBAN",
                        displayName: "IBAN",
                        field: "IBAN",
                        cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                 <input type="text" maxlength="50" name="IBAN-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                                </p>
                            </form>`,
                    },
                    {
                        name: "BranchNumber",
                        displayName: "Branch Number",
                        field: "BranchNumber",
                        cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                 <input type="text" maxlength="50" name="IBAN-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                                </p>
                            </form>`,
                    },
                    {
                        name: "StartDate",
                        displayName: "* Start Date",
                        field: "StartDate",
                        cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },
                        width: 150,
                        cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="StartDate"></gts-date>
                            </div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}"></gts-date>
                                </form>`,
                    },
                    {
                        name: "EndDate",
                        displayName: "* End Date",
                        field: "EndDate",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        width: 150,
                        cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="StartDate" ng-disabled="grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails"></gts-date>
                            </div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}" ng-change="grid.appScope.supplierUpdateCtrl.bankValueChanged(row.entity)" ng-disabled="grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails"></gts-date>
                                </form>`,
                    },
                    {
                        name: "IsReimbursing",
                        displayName: "Reimbursing",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableFiltering: false,
                        cellTemplate: `<input type="checkbox" ng-model="row.entity.IsReimbursing" ng-change="grid.appScope.supplierUpdateCtrl.checkBoxBankChanged(row.entity)" ng-disabled="grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails">`,
                        editableCellTemplate: `<form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-model="row.entity.IsReimbursing" ng-disabled="grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails">
                                                    </p>
                                                </form>`,
                        width: 115
                    },
                    {
                        name: "IsActive",
                        displayName: "Active",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableFiltering: false,
                        cellTemplate: `<input type="checkbox" ng-model="row.entity.IsActive" ng-change="grid.appScope.supplierUpdateCtrl.checkBoxBankChanged(row.entity)" ng-disabled="grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails">`,
                        editableCellTemplate: `<form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-model="row.entity.IsActive" ng-disabled="grid.appScope.supplierUpdateCtrl.supplierMaster.LockdownType >= 2 || !grid.appScope.supplierUpdateCtrl.supplierMaster.EditBankingDetails">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };

            showSupplierIntermediaryBankDetails_click(supplierBank: interfaces.master.ISupplierBank){
                this.showSupplierIntermediaryBankDetails(supplierBank)
            }

            showSupplierIntermediaryBankDetails(supplierBank: interfaces.master.ISupplierBank){

                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                            <div class="modal-header">
                              <h3 class="modal-title" id="modal-title">Intermediary Bank Details</h3>
                                </div>
                                <div bs-loading-overlay bs-loading-overlay-reference-id="acclines.update">
                                    <form name="IntermediaryBankDetails">
                                        <gts-message-display messages="Messages"></gts-message-display>
                                        <div class="modal-body" id="modal-body">
                                            <div class="gts-padding">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <req></req>
                                                                <label>Intermediary Bank Name</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                    <input  ng-disabled="intermediaryBankCtrl.supplierBank.Id" class="form-control" type="text" ng-model="intermediaryBankCtrl.supplierBank.IntermediateBankName" name="IntermediateBankName" aria-label="Intermediate Bank Name" required="true" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <opt></opt>
                                                                <label>Branch Name</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                     <input  ng-disabled="intermediaryBankCtrl.supplierBank.Id" class="form-control" type="text" ng-model="intermediaryBankCtrl.supplierBank.IntermediateBankBranch" name="IntermediateBankBranch" aria-label="Intermediate Bank Branch" required="false" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <opt></opt>
                                                                <label>Swift Code</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                     <input  ng-disabled="intermediaryBankCtrl.supplierBank.Id" class="form-control" type="text" ng-model="intermediaryBankCtrl.supplierBank.IntermediateBankSwiftCode" name="IntermediateBankSwiftCode" aria-label="Intermediate Swift Code" required="false" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <opt></opt>
                                                                <label>IBAN Code</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                     <input  ng-disabled="intermediaryBankCtrl.supplierBank.Id" class="form-control" type="text" ng-model="intermediaryBankCtrl.supplierBank.IntermediateBankIBANCode" name="IntermediateBankClearingCode" aria-label="Intermediate Bank Clearing Code" required="false" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <opt></opt>
                                                                <label>Clearing Code</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                     <input  ng-disabled="intermediaryBankCtrl.supplierBank.Id" class="form-control" type="text" ng-model="intermediaryBankCtrl.supplierBank.IntermediateBankClearingCode" name="IntermediateBankIBANCode" aria-label="Intermediate Bank IBAN Code" required="false" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <opt></opt>
                                                                <label>Physical Address</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <gts-address-modal-popup transaction-id="intermediaryBankCtrl.supplierBank.Id" transaction-type="12" ng-disabled="intermediaryBankCtrl.LockdownType >= 2 || !intermediaryBankCtrl.supplierBank.Id" show-country="true" hide-postal="true"></gts-address-modal-popup>
                                                            </div>
                                                        </div>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button class="btn btn-primary"  ng-disabled="intermediaryBankCtrl.LockdownType>=4" type="button" ng-click="intermediaryBankCtrl.save()">Submit</button>
                                                <button class="btn btn-primary" type="button" ng-click="intermediaryBankCtrl.cancel()">Close</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        `,
                    controller: class intermediaryBankCtrl {
                        messages: interfaces.applicationcore.IMessage[];                      
                        supplierBank: interfaces.master.ISupplierBank;
                        constructor(
                            $scope,
                            private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,                         
                            private LockdownType : number
                        ) {                          
                                this.supplierBank = supplierBank;                                                          
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                      



                        save() {
                        
                            this.$uibModalInstance.close(this.supplierBank);
                        }

                    

                    },
                    controllerAs: "intermediaryBankCtrl",
                    resolve: {
                        LockdownType: this.supplierMaster.LockdownType
                    }
                }).result.then((result) => {
                    if (result) {

                    }
                });

            }

            downloadBankingToExcel() {
                this.isLoadingDetail = true;

                    this.supplierService.getSupplierBankingExcel(this.comId).then(() => {
                        this.$anchorScroll("topAnchor");
                        this.$state.go("auth.MasterData.Supplier.Update", { comId: this.comId });
                        return true;
                    }, (data) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);

                        return false;
                        });

                this.isLoadingDetail = false;
            }

            SaveSupplier(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiSupplierTemplate) {
                    this.supplierMaster.SupplierTemplateList = _.map(this.apiSupplierTemplate.rowEdit.getDirtyRows(this.apiSupplierTemplate.grid), (o) => { return o.entity });
                }
                else {
                    this.supplierMaster.SupplierTemplateList = [];
                }

                if (this.apiSupplierBanking) {
                    this.supplierMaster.SupplierBankList = _.map(this.apiSupplierBanking.rowEdit.getDirtyRows(this.apiSupplierBanking.grid), (o) => { return o.entity });
                }
                else {
                    this.supplierMaster.SupplierBankList = [];
                }

                this.supplierService.save().save(this.supplierMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    if (this.apiSupplierTemplate) {
                        this.apiSupplierTemplate.rowEdit.setRowsClean(this.supplierMaster.SupplierTemplateList);
                    }
                    if (this.apiSupplierBanking) {
                        this.apiSupplierBanking.rowEdit.setRowsClean(this.supplierMaster.SupplierBankList);
                    }

                    this.comId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveSupplier().then((data: boolean) => {
                            if (data) {
                                this.loadSupplier(this.comId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Supplier.Update", { comId: this.comId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveSupplier().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

            getDocuments(showAll:boolean) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.supplierMaster.Code, systemType: Enum.EnumSystemType.Company, ownerEntityId: this.supplierMaster.EntityId, showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.documentRepository = documentRepository;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }
        };

        angular.module("app").controller("supplierUpdateCtrl", controllers.master.supplierUpdateCtrl);
    }
}
